import React, { useState } from "react";
import "./App.css";

const App = () => {
  const [tasks, setTasks] = useState([]);
  const [taskInput, setTaskInput] = useState("");
  const [editingTaskId, setEditingTaskId] = useState(null);
  const [lastActionTime, setLastActionTime] = useState(null);

  // Add Task
  const addTask = () => {
    if (taskInput.trim() === "") return;
    const newTask = {
      id: Date.now(),
      text: taskInput,
      lastModified: new Date(),
    };
    setTasks([...tasks, newTask]);
    setTaskInput("");
    setLastActionTime(new Date());
  };

  // Edit Task
  const editTask = (id) => {
    const taskToEdit = tasks.find((task) => task.id === id);
    setTaskInput(taskToEdit.text);
    setEditingTaskId(id);
  };

  // Save Edited Task
  const saveTask = () => {
    setTasks(
      tasks.map((task) =>
        task.id === editingTaskId
          ? { ...task, text: taskInput, lastModified: new Date() }
          : task
      )
    );
    setTaskInput("");
    setEditingTaskId(null);
    setLastActionTime(new Date());
  };

  // Delete Task
  const deleteTask = (id) => {
    setTasks(tasks.filter((task) => task.id !== id));
    setLastActionTime(new Date());
  };

  return (
    <div className="app-container">
      <h1>React To-Do App</h1>
      <div className="input-container">
        <input
          type="text"
          placeholder="Enter task"
          value={taskInput}
          onChange={(e) => setTaskInput(e.target.value)}
        />
        {editingTaskId ? (
          <button onClick={saveTask}>Save</button>
        ) : (
          <button onClick={addTask}>Add</button>
        )}
      </div>
      <ul className="task-list">
        {tasks.map((task) => (
          <li key={task.id} className="task-item">
            <span>{task.text}</span>
            <span className="time">
              Last edited: {task.lastModified.toLocaleString()}
            </span>
            <div className="task-actions">
              <button onClick={() => editTask(task.id)}>Edit</button>
              <button onClick={() => deleteTask(task.id)}>Delete</button>
            </div>
          </li>
        ))}
      </ul>
      {lastActionTime && (
        <p className="last-action">
          Last action performed: {lastActionTime.toLocaleString()}
        </p>
      )}
    </div>
  );
};

export default App;
